import dayjs from "dayjs"
import { isNotSmets2 } from "@soenergy/frontend-library/src/helpers/meterTypeHelper"
import { postcodeValidation } from "@soenergy/frontend-library/src/helpers/postcodeValidationHelper"
import AuthenticationService from "@soenergy/frontend-library/src/services/AuthenticationService"
import UserService from "@/services/UserService"
import ErrorReporter from "@soenergy/frontend-library/src/services/ErrorReporter"
import GatewayPortal from "@/services/GatewayPortal"
import { sessionStore } from "@soenergy/frontend-library/src/services/StorageFactory"
import { appointmentTypeMapping, installEligibilityReasons } from "@/constants"

export const mutations = {
  SET_JUNIFER_DATA(state, value) {
    state.junifer = value
  },
  SET_ACCOUNTS(state, value) {
    state.accounts = value
  },
  SET_METER_REPAIR_ELIGIBILITY(state, value) {
    state.meterRepairEligibility = value
  },
  SET_METER_INSTALL_ELIGIBILITY(state, value) {
    state.meterInstallEligibility = value
  },
  SET_BOOKING_DATA(state, data) {
    state.bookingData = data
  },
  SET_CUSTOMER_ID(state, data) {
    state.customerId = data
    sessionStore.setItem("customerId", data)
  },
  SET_JUNIFER_ACCOUNT_ID(state, data) {
    state.juniferAccountId = data
    sessionStore.setItem("juniferAccountId", data)
  },
  SET_AUTHENTICATED(state, data) {
    state.authenticated = data
  },
  UPDATE_LOADING(state, isLoading) {
    state.loading = isLoading
  },
  SET_AUTH_ERROR(state, show) {
    state.showAuthError = show
  },
  SET_SUBMIT_STATUS(state, status) {
    state.submitStatus = status
  },
  SET_SUBMIT_ERROR(state, error) {
    state.submitError = error
  },
  SET_REGISTER_INTEREST_STATUS(state, status) {
    state.registerInterestStatus = status
  },
}

export const actions = {
  validateStepTitle({ commit, state }) {
    if (state.bookingData.length) {
      const isSingle =
        state.bookingData[0].fuel_type === "ELEC_ONLY" ||
        state.bookingData[0].fuel_type === "GAS_ONLY"
      let yourMeterStepTitle = ""
      let pageIndex = 0

      for (let i = 0; i < state.steps.length; i++) {
        if (state.steps[i].name === "YourMeter") {
          yourMeterStepTitle = state.steps[i].title
          pageIndex = i
        }
      }

      let titleChange = isSingle ? yourMeterStepTitle : yourMeterStepTitle + "s"
      commit("SET_STEP_TITLE", { pageIndex, value: titleChange })
    }
  },
  async logout() {
    await AuthenticationService.logout(true)
    window.location.assign(process.env.VUE_APP_WEBSITE_URL)
  },
  async fetchJuniferData({ commit }) {
    await UserService.fetchJunifer()
      .then((response) => {
        if (response.data) {
          commit("SET_JUNIFER_DATA", response.data)

          if (response.data.accounts) {
            const accounts = Object.values(response.data.accounts)
            commit("SET_ACCOUNTS", accounts)
          }
        }
      })
      .catch((err) => {
        ErrorReporter.report(err)
      })
  },
  async bookAppointment({ commit, state, getters }, appointmentType) {
    const payload = {
      "aes-patch-availability-id":
        state.appointment.selectedAppointmentSlot.time.id,
      "slot-start-date-time": getters.startDateTime,
      "slot-end-date-time": getters.endDateTime,
      "customer-id": state.junifer.customer.id.toString(),
      "customer-first-name": state.homeDuringAppointment.form.firstName,
      "customer-last-name": state.homeDuringAppointment.form.lastName,
      "customer-email": getters.contactEmail,
      "customer-phone": getters.contactPhone,
      "customer-address": getters.address,
      "customer-city": getters.city,
      "customer-postcode": getters.validatedPostcode,
      "junifer-account-id": state.juniferAccountId.toString(),
      "gas-meter-serial-number": getters.gasMeterSerialNumber,
      "electricity-meter-serial-number": getters.electricityMeterSerialNumber,
      "medical-equipment": getters.hasMedicalEquipment,
      "booking-source": "Portal",
      mpan: getters.mpan,
      mprn: getters.mprn,
      comment: state.appointment.comment,
    }

    if (getters.isEligibleForRepair && appointmentType === "repair") {
      payload["appointment-type"] = getters.appointmentType
    }

    commit("SET_SUBMIT_STATUS", "PENDING")

    try {
      await GatewayPortal.createAppointment(payload, state.customerId)
      commit("SET_SUBMIT_STATUS", "SUCCESS")
    } catch (error) {
      commit("SET_SUBMIT_STATUS", "ERROR")
      commit("SET_SUBMIT_ERROR", error?.response?.data?.message)
      throw error
    }
  },
  async registerInterest(
    { commit },
    { customerComment, isReschedule = false, interestReason }
  ) {
    const payload = {
      junifer_account_number: this.getters.accountNumber,
      customer_name: this.getters.fullName,
      customer_email: this.getters.primaryEmail,
      mpans: this.getters.mpan,
      mprns: this.getters.mprn,
      reschedule_attempt: isReschedule,
      customer_comment: customerComment,
      interest_reason: interestReason,
    }

    commit("SET_REGISTER_INTEREST_STATUS", "PENDING")

    try {
      await GatewayPortal.registerInterest(payload)
      commit("SET_REGISTER_INTEREST_STATUS", "SUCCESS")
    } catch (error) {
      commit("SET_REGISTER_INTEREST_STATUS", "ERROR")
      throw error
    }
  },
  async getAccountId({ commit }) {
    try {
      const response = await UserService.getAccountId()
      if (response?.data) {
        const accountId = response.data.current_account_id
        commit("SET_CUSTOMER_ID", accountId)

        const juniferAccountId =
          response.data.junifer_current_account_id || accountId
        commit("SET_JUNIFER_ACCOUNT_ID", juniferAccountId)

        if (!accountId) {
          commit("SET_AUTH_ERROR", true)
        }
      }
    } catch (err) {
      ErrorReporter.report(err)
      throw err
    }
  },
  async getMeterInstallEligibility({ commit, getters }) {
    await GatewayPortal.getMeterInstallEligibility(getters.accountNumber)
      .then((response) => {
        if (response.data) {
          commit("SET_METER_INSTALL_ELIGIBILITY", response.data)
        }
      })
      .catch((err) => {
        ErrorReporter.report(err)
      })
  },
  async getMeterRepairEligibility({ state, commit }) {
    await GatewayPortal.getMeterRepairEligibility(state.juniferAccountId)
      .then((response) => {
        if (response.data) {
          commit("SET_METER_REPAIR_ELIGIBILITY", response.data)
        }
      })
      .catch((err) => {
        ErrorReporter.report(err)
      })
  },
  async getAppointmentEligibility(
    { dispatch },
    checkMeterInstallEligibility = true
  ) {
    const promises = [dispatch("getMeterRepairEligibility")]

    if (checkMeterInstallEligibility) {
      promises.push(dispatch("getMeterInstallEligibility"))
    }

    await Promise.allSettled(promises)
  },
}

export const getters = {
  customerId(state) {
    return Boolean(state.customerId)
  },
  isAuthenticated(state) {
    return Boolean(state.customerId && state.authenticated)
  },
  hasActiveAgreements(state) {
    const today = dayjs().endOf("day")
    return state.accounts.some((account) => {
      return account.agreements.some(
        (agreement) =>
          !agreement.cancelled &&
          (dayjs().isBetween(
            dayjs(agreement.fromDt),
            agreement.toDt ? dayjs(agreement.toDt) : today,
            "()"
          ) ||
            dayjs(agreement.fromDt).isAfter(today))
      )
    })
  },
  isSwitchingAway(state) {
    return (
      state.meterInstallEligibility?.reason ===
      installEligibilityReasons.INELIGIBLE_SWITCHING_AWAY
    )
  },
  hasSmartMeter(state) {
    return (
      state.meterInstallEligibility?.reason ===
      installEligibilityReasons.INELIGIBLE_ALREADY_SMART
    )
  },
  ineligibleTooComplex(state) {
    return (
      state.meterInstallEligibility?.reason ===
      installEligibilityReasons.INELIGIBLE_TOO_COMPLEX
    )
  },
  fullName(state) {
    return state.junifer?.customer?.forename && state.junifer?.customer?.surname
      ? `${state.junifer.customer.forename} ${state.junifer.customer.surname}`
      : ""
  },
  phoneNumber(state) {
    return state.junifer.customer.primaryContact.phoneNumber1 || ""
  },
  primaryEmail(state) {
    return state.junifer?.customer?.primaryContact.email || ""
  },
  address(state, getters) {
    if (!getters.meterpointAddress) return null

    const { address1, address2, address3 } = getters.meterpointAddress
    return [address1, address2, address3].filter(Boolean).join(", ")
  },
  city(state) {
    const city = Object.values(
      state.junifer.accounts[state.customerId].meterpoint_addresses
    )[0].address4
    // the backend is expecting a string so we need to return "Unknown" if the city is undefined
    return city || "Unknown"
  },
  postcode(state, getters) {
    return getters.meterpointAddress?.postcode || null
  },
  meterpointAddress(state) {
    const meterpointAddress = Object.values(
      state.junifer.accounts[state.customerId].meterpoint_addresses
    )

    return meterpointAddress?.length ? meterpointAddress[0] : null
  },
  validatedPostcode(state, getters) {
    if (!getters.postcode) return null
    return postcodeValidation(getters.postcode)
  },
  fullAddress(state, getters) {
    return `${getters.address}, ${getters.postcode}`
  },
  accountNumber(state) {
    return (
      state.accounts?.reduce((account, accounts) => {
        if (accounts.account.id == state.customerId)
          account = accounts.account.number
        return account
      }, 0) || null
    )
  },
  outCode(state) {
    if (!state.junifer) return null
    const postcode = state.junifer.accounts[state.customerId].postcode
    return postcode.replace(/\s+/g, "").slice(0, -3)
  },
  eligibleMeterpoints(state) {
    const activeSupplyStatuses = [
      "RegistrationAccepted",
      "Registered",
      "RegistrationConfirmed",
      "RegistrationCompleted",
      "RegistrationRequested",
      "LossObjected",
      "LossObjectionRaised",
      "LossObjectionUpheld",
    ]
    const meterpoints = state.junifer.accounts[state.customerId].meterpoints
    if (!meterpoints) return []
    const activeMeterpoints = Object.values(meterpoints).filter((meterpoint) =>
      activeSupplyStatuses.includes(meterpoint.supplyStatus)
    )
    const nonSmartMeterpoints = activeMeterpoints.filter((meterpoint) =>
      meterpoint.meters.some((meter) => isNotSmets2(meter.meterType))
    )
    return nonSmartMeterpoints
  },
  gasMeterpoint(state, getters) {
    return (
      getters.eligibleMeterpoints.find(
        (meterpoint) => meterpoint.type === "MPRN"
      ) || null
    )
  },
  electricityMeterpoint(state, getters) {
    return (
      getters.eligibleMeterpoints.find(
        (meterpoint) => meterpoint.type === "MPAN"
      ) || null
    )
  },
  mprn(state, getters) {
    return getters.gasMeterpoint?.identifier || ""
  },
  mpan(state, getters) {
    return getters.electricityMeterpoint?.identifier || ""
  },
  gasMeterSerialNumber(state, getters) {
    const serialNumber =
      getters.gasMeterpoint?.meters.find((meter) => meter.identifier)
        ?.identifier || ""

    return serialNumber.replace(/\s+/g, "")
  },
  electricityMeterSerialNumber(state, getters) {
    const serialNumber =
      getters.electricityMeterpoint?.meters.find((meter) => meter.identifier)
        ?.identifier || ""
    return serialNumber.replace(/\s+/g, "")
  },
  isEligibleForInstall(state) {
    const installEligibleValues = [
      installEligibilityReasons.ELIGIBLE_DUAL_EXCHANGE,
      installEligibilityReasons.ELIGIBLE_ELEC_EXCHANGE,
      installEligibilityReasons.ELIGIBLE_GAS_EXCHANGE,
    ]
    return installEligibleValues.includes(state.meterInstallEligibility?.reason)
  },
  isEligibleForRepair(state) {
    const appointmentType = state.meterRepairEligibility?.appointmentType
    if (!appointmentType) return false
    return !appointmentType.includes("install")
  },
  appointmentType(state) {
    const appointmentType = state.meterRepairEligibility?.appointmentType
    return appointmentTypeMapping[appointmentType] || null
  },
  registerInterestSubmitStatus(state) {
    return {
      isPending: state.registerInterestStatus === "PENDING",
      isSuccess: state.registerInterestStatus === "SUCCESS",
      isError: state.registerInterestStatus === "ERROR",
    }
  },
}
