<template>
  <section v-if="pageContent" class="choose-appointment">
    <div class="choose-appointment__wrapper">
      <p class="choose-appointment__title">
        Step {{ currentStepNumber }}: {{ pageContent.stepTitle }}
      </p>
      <Article
        v-if="!isRepairAppointment && appointmentInstallCopy?.text"
        :text-content="appointmentInstallCopy.text"
        data-testid="appointment-install-info"
        class="choose-appointment__install-info"
      />
      <Article
        v-if="isRepairAppointment && appointmentTypeCopy"
        :text-content="appointmentTypeCopy"
        class="choose-appointment__repair-info"
        no-margin
      />
      <Spinner v-if="slotsPending" spinner-text="Loading..." />
      <div v-else>
        <p class="choose-appointment__address">
          {{ pageContent.installationAddressText }} {{ fullAddress }}
        </p>
        <RegisterInterest
          v-if="noSuitableAppointments || hasNoSlots"
          :has-no-slots="hasNoSlots"
          :no-suitable-appointments="noSuitableAppointments"
        />
        <template v-else>
          <p class="choose-appointment__select-time-text">
            {{ pageContent.selectDateText }}
          </p>
          <DateTimePicker
            v-model="selectedAppointmentSlot"
            :days="days"
            :available-slots="availableSlots"
            :appointment-info-content="appointmentInfoContent"
          />
          <AppButton
            v-if="nextButtonProps"
            v-track="nextButtonProps.tracking"
            class="choose-appointment__next-button"
            :disabled="!validated"
            @click="nextStep"
          >
            <span> {{ nextButtonProps.label }} </span>
            <span class="customer-details__icon icon icon-arrow-right" />
          </AppButton>
          <AppButton
            variant="invertedPrimary"
            full-width
            class="mt-5"
            @click="noSuitableAppointments = true"
          >
            None of these slots work for me
          </AppButton>
        </template>
      </div>
    </div>
  </section>
  <EligibilityModal
    v-if="modal.isModalVisible"
    :show="modal.isModalVisible"
    :variation="modal.variation"
  />
</template>

<script>
import { mapGetters, mapState } from "vuex"
import GatewayPortal from "@/services/GatewayPortal"
import AppButton from "@soenergy/frontend-library/src/components/AppButton.vue"
import Spinner from "@soenergy/frontend-library/src/components/Spinner.vue"
import Article from "@soenergy/frontend-library/src/components/Article.vue"
import cmsPreviewMixin from "@soenergy/frontend-library/src/mixins/cmsPreviewMixin"
import EligibilityModal from "@/components/EligibilityModal.vue"
import DateTimePicker from "@/components/DateTimePicker.vue"
import RegisterInterest from "@/components/RegisterInterest.vue"
import chooseAppointmentContent from "soenergy-cms-loader!?path=smbp/choose-appointment"
import { installEligibilityReasons } from "@/constants"

export default {
  components: {
    AppButton,
    EligibilityModal,
    DateTimePicker,
    Spinner,
    Article,
    RegisterInterest,
  },
  mixins: [cmsPreviewMixin({ story: chooseAppointmentContent })],
  props: {
    currentStepNumber: {
      type: Number,
      default: null,
    },
  },
  emits: ["go-forward"],
  data() {
    return {
      availableSlots: [],
      days: [],
      slotsPending: false,
      hasNoSlots: false,
      modal: {
        isModalVisible: false,
        variation: null,
      },
      noSuitableAppointments: false,
    }
  },
  computed: {
    ...mapState({
      meterRepairEligibility: (state) => state.meterRepairEligibility,
      meterInstallEligibility: (state) => state.meterInstallEligibility,
    }),
    ...mapGetters([
      "fullAddress",
      "validatedPostcode",
      "hasActiveAgreements",
      "isSwitchingAway",
      "hasSmartMeter",
      "isEligibleForInstall",
      "isEligibleForRepair",
      "appointmentType",
      "ineligibleTooComplex",
    ]),
    selectedAppointmentSlot: {
      get() {
        return this.$store.state.appointment.selectedAppointmentSlot
      },
      set(value) {
        this.$store.commit("SET_SELECTED_APPOINTMENT_SLOT", value)
      },
    },
    validated() {
      return (
        !!this.selectedAppointmentSlot?.date &&
        !!this.selectedAppointmentSlot?.time &&
        !this.slotsPending
      )
    },
    nextButtonProps() {
      return this.pageContent?.nextStepButton?.[0] ?? null
    },
    backToAccountButtonProps() {
      return this.pageContent?.backToAccountButton?.[0] ?? null
    },
    noSlotsAvailableContent() {
      return this.pageContent?.noSlotsMessage?.[0]?.textContent ?? null
    },
    appointmentInfoContent() {
      return this.pageContent?.appointmentInfoMessage?.[0]?.textContent ?? null
    },
    isRepairAppointment() {
      return this.$route.meta.appointmentType === "repair"
    },
    appointmentTypeCopy() {
      const meterRepairEligibility =
        this.meterRepairEligibility?.appointmentType
      const appointmentTypeCopies = this.pageContent?.appointmentTypeCopies
      if (!meterRepairEligibility) return null

      const appointmentCopy = appointmentTypeCopies
        .flatMap((copy) => copy.appointmentTypeCopy)
        .find((copy) => copy.name === meterRepairEligibility)

      return appointmentCopy?.text?.content?.[0] ?? null
    },
    appointmentInstallType() {
      if (!this.isEligibleForInstall || this.isRepairAppointment) return null

      const { gasMeterType, elecMeterType, reason } =
        this.meterInstallEligibility

      if (reason === installEligibilityReasons.ELIGIBLE_GAS_EXCHANGE)
        return "gasExchange"
      else if (reason === installEligibilityReasons.ELIGIBLE_ELEC_EXCHANGE)
        return "elecExchange"
      else if (![gasMeterType, elecMeterType].includes("SMETS1"))
        return "dualExchangeHasTrad"
      else return "dualExchangeHasSmets1"
    },
    appointmentInstallCopy() {
      if (!this.pageContent?.appointmentInstallCopies) return null

      const content = this.pageContent.appointmentInstallCopies.find(
        (copy) => copy.name === this.appointmentInstallType
      )
      return content ?? null
    },
  },
  watch: {
    appointmentInstallCopy: {
      handler(val) {
        this.$store.commit("SET_COMMENT", val?.comment ?? "")
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.slotsPending = true
    this.checkEligibility()
  },
  methods: {
    handleSelectedAppointmentSlot(appointmentSlot) {
      this.selectedAppointmentSlot = appointmentSlot
    },
    nextStep() {
      this.$emit("go-forward")
    },
    fetchSlots() {
      GatewayPortal.getAvailableSlots(this.validatedPostcode)
        .then((response) => {
          if (response && response.data && response.data.days) {
            this.availableSlots = response.data.days
            this.days = response.data.days
              .filter((day) => day.slots.length)
              .map((day) => new Date(day.date))
          }
        })
        .catch((err) => {
          this.hasNoSlots = true
          this.$store.commit("RESET_CALENDER")
          throw err
        })
        .finally(() => {
          if (!this.days.length) {
            this.hasNoSlots = true
          }
          this.slotsPending = false
        })
    },
    checkEligibility() {
      if (this.isRepairAppointment) {
        this.handleRepairEligibility()
      } else {
        this.handleInstallEligibility()
      }
    },
    handleRepairEligibility() {
      if (!this.isEligibleForRepair) {
        return this.setModal("repairIneligible")
      }
      return this.fetchSlots()
    },
    handleInstallEligibility() {
      switch (true) {
        case this.isSwitchingAway:
          return this.setModal("switchingAway")
        case !this.hasActiveAgreements:
          return this.setModal("notOnSupply")
        case this.hasSmartMeter:
          return this.setModal("hasSmartMeter")
        case this.ineligibleTooComplex:
          return this.setModal("ineligibleTooComplex")
        case !this.isEligibleForInstall:
          return this.setModal("ineligible")
        default:
          return this.fetchSlots()
      }
    },
    setModal(modalVariant) {
      this.modal.variation = modalVariant
      this.modal.isModalVisible = true
      this.$store.commit("RESET_CALENDER")
    },
  },
}
</script>

<style scoped lang="scss">
.choose-appointment {
  &__wrapper {
    margin-bottom: $space-16;

    @include md {
      margin-bottom: $space-12;
    }
  }

  &__title {
    font-size: $font-size-5;
    font-weight: $weight-medium;
    margin-bottom: $space-4;

    @include md {
      font-size: $font-size-6;
    }
  }

  &__address {
    font-size: $font-size-3;
    font-weight: $weight-medium;
    margin-bottom: $space-2;

    @include md {
      font-size: $font-size-5;
    }
  }

  &__select-time-text {
    font-size: $font-size-3;
    margin-bottom: $space-5;

    @include md {
      font-size: $font-size-4;
    }
  }

  &__next-button.button {
    position: fixed;
    bottom: $space-4;
    left: 50%;
    transform: translateX(-50%);
    z-index: zindex(sticky);
    width: 90%;

    @include md {
      width: 100%;
      position: static;
      transform: translateX(0);
      margin-top: 2rem;
    }
  }

  &__repair-info {
    font-size: $font-size-3;
    margin-bottom: $space-5;

    @include md {
      font-size: $font-size-4;
    }
  }
}
</style>
