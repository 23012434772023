<template>
  <LoadingOverlay :is-active="fetchSlotsStatus === 'LOADING'" />
  <div v-if="pageContent" class="smart-meter">
    <div class="reschedule-appointment">
      <BackLink class="reschedule-appointment__back-link" />
      <h3 class="reschedule-appointment__heading">{{ pageContent.title }}</h3>
      <p class="reschedule-appointment__installation-address">
        {{ pageContent.installationAddressText }} {{ booking.fullAddress }}
      </p>
      <p class="reschedule-appointment__current-booking">
        {{ pageContent.currentBookingText }}
        {{ booking.appointmentDateFormatted }} -
        {{ booking.appointmentTimeSlot }}
      </p>
      <RegisterInterest
        v-if="noSuitableAppointments || hasNoSlots"
        :has-no-slots="hasNoSlots"
        :no-suitable-appointments="noSuitableAppointments"
        is-reschedule
      />
      <template v-else>
        <p class="reschedule-appointment__calander-label">
          {{ pageContent.selectDateText }}
        </p>
        <DateTimePicker
          v-model="selectedAppointmentSlot"
          :days="days"
          :available-slots="availableSlots"
          :appointment-info-content="pageContent.appointmentInfoContent"
        />
        <div class="reschedule-appointment__button-wrapper">
          <AppButton
            v-bind="cmsBlockToProps(pageContent.cancelButton)"
            ref="cancel-button"
            class="reschedule-appointment__button"
            variant="invertedPrimary"
            @click="$router.back()"
          />
          <AppButton
            v-bind="cmsBlockToProps(pageContent.submitButton)"
            ref="submit-button"
            :disabled="
              !selectedAppointmentSlot?.date ||
              !selectedAppointmentSlot?.time ||
              updateBookingStatus === 'PENDING'
            "
            :loading="updateBookingStatus === 'PENDING'"
            class="reschedule-appointment__button"
            @click="rescheduleAppointment"
          />
        </div>
        <AppButton
          variant="invertedPrimary"
          full-width
          class="mt-5"
          @click="noSuitableAppointments = true"
        >
          None of these slots work for me
        </AppButton>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex"
import GatewayPortal from "@/services/GatewayPortal"
import AppButton from "@soenergy/frontend-library/src/components/AppButton.vue"
import BackLink from "@soenergy/frontend-library/src/components/BackLink.vue"
import LoadingOverlay from "@soenergy/frontend-library/src/components/LoadingOverlay.vue"
import cmsPreviewMixin from "@soenergy/frontend-library/src/mixins/cmsPreviewMixin"
import DateTimePicker from "@/components/DateTimePicker.vue"
import RegisterInterest from "@/components/RegisterInterest.vue"
import rescheduleAppointment from "soenergy-cms-loader!?path=smbp/reschedule-appointment"

export default {
  components: {
    AppButton,
    BackLink,
    LoadingOverlay,
    DateTimePicker,
    RegisterInterest,
  },
  mixins: [cmsPreviewMixin({ story: rescheduleAppointment })],
  data() {
    return {
      availableSlots: [],
      days: [],
      fetchSlotsStatus: "LOADING",
      updateBookingStatus: null,
      noSuitableAppointments: false,
      hasNoSlots: false,
    }
  },
  computed: {
    ...mapState({
      customerId: (state) => state.customerId,
      bookings: (state) => state.bookingDetails.bookings,
    }),
    ...mapGetters({
      validatedPostcode: "validatedPostcode",
      startDateTime: "startDateTime",
      endDateTime: "endDateTime",
    }),
    jobNumber() {
      return this.$route.query.jobNumber
    },
    booking() {
      return this.bookings.find(
        (booking) => booking.jobNumber == this.jobNumber
      )
    },
    selectedAppointmentSlot: {
      get() {
        return this.$store.state.appointment.selectedAppointmentSlot
      },
      set(value) {
        this.$store.commit("SET_SELECTED_APPOINTMENT_SLOT", value)
      },
    },
  },
  async mounted() {
    if (!this.isFetched) {
      await this.$store.dispatch("bookingDetails/fetchBookingData")
    }
    this.fetchSlots()
  },
  methods: {
    async fetchSlots() {
      this.fetchSlotsStatus = "LOADING"
      try {
        const response = await GatewayPortal.getAvailableSlots(
          this.validatedPostcode
        )
        if (response?.data?.days?.length) {
          this.availableSlots = response.data.days
          this.days = response.data.days
            .filter((day) => day.slots.length)
            .map((day) => new Date(day.date))
        }
        this.fetchSlotsStatus = "SUCCESS"
      } catch (error) {
        this.hasNoSlots = true
        this.fetchSlotsStatus = "ERROR"
        throw error
      } finally {
        if (!this.days.length) {
          this.hasNoSlots = true
        }
      }
    },
    async rescheduleAppointment() {
      const payload = {
        "junifer-id": this.customerId,
        "job-number": this.jobNumber,
        "slot-start-datetime": this.startDateTime,
        "slot-end-datetime": this.endDateTime,
      }
      this.updateBookingStatus = "PENDING"
      try {
        await GatewayPortal.updateBooking(payload)
        this.updateBookingStatus = "SUCCESS"
        this.$router.push("/appointment-details")
      } catch (error) {
        this.updateBookingStatus = "ERROR"
        throw error
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.reschedule-appointment {
  align-items: center;
  margin: auto;
  padding: 0 $space-4;
  font-size: $font-size-3;
  padding-bottom: $space-17;

  @include md {
    width: $width-5;
  }

  @include lg {
    font-size: $font-size-5;
  }

  &__back-link {
    margin-top: $space-6;
    margin-bottom: $space-8;
  }

  &__heading {
    text-transform: none;
    margin-bottom: $space-5;
    font-size: $font-size-5;

    @include lg {
      margin-bottom: $space-4;
      font-size: $font-size-6;
    }
  }

  &__installation-address {
    font-weight: $weight-medium;
    margin-bottom: $space-5;

    @include lg {
      margin-bottom: $space-8;
    }
  }

  &__current-booking {
    font-weight: $weight-medium;
    margin-bottom: $space-3;
  }

  &__calander-label {
    margin-bottom: $space-8;

    @include lg {
      font-size: $font-size-4;
    }
  }

  &__dialog {
    margin-top: $space-4;
  }

  &__button-wrapper {
    display: flex;
    gap: $space-4;
    position: fixed;
    width: 100%;
    left: 0;
    bottom: $space-4;
    padding: 0 $space-4;
    z-index: zindex(sticky);

    @include md {
      position: static;
      padding: 0;
      margin-top: $space-6;
    }
  }

  &__button {
    flex: 1;
  }
}

::v-deep .segmented-group__form {
  gap: $space-3;
  @include md {
    gap: $space-4;
  }
}

::v-deep .segmented-button {
  &__label {
    font-size: $font-size-2;
    padding: $space-2 $space-3;

    @include md {
      padding: $space-2 $space-4;
    }
  }
}
</style>
